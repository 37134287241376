import { getDefaultConfig, Chain } from '@rainbow-me/rainbowkit';
import {
  base,
  mainnet,
  polygon,
  sepolia
} from 'wagmi/chains';

const polygonZkEvmCardona: Chain = {
  id: 2442,
  name: 'Polygon zkEVM Cardona',
  iconUrl: '',
  iconBackground: '#fff',
  nativeCurrency: {
    name: 'Cardona Token',
    symbol: 'CADA',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['https://rpc.cardona.zkevm-rpc.com'] },
  },
  blockExplorers: {
    default: { name: 'Cardona Explorer', url: 'https://cardona-zkevm.polygonscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 114091,
    },
  },
} as const satisfies Chain;

const walletConnectProjectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

export const config = getDefaultConfig({
  appName: 'Clima',
  projectId: walletConnectProjectId!,
  chains: [
    mainnet,
    polygon,
    base,
    sepolia,
    polygonZkEvmCardona,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === 'true' ? [sepolia, polygonZkEvmCardona] : []),
  ],
  ssr: true,
});
