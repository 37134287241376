"use client";

import React, { useState, useCallback, ComponentType, useEffect } from "react";
import { useAccount } from 'wagmi';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { ConnectWallet } from "./ConnectWallet";
import type { MenuInfo } from 'rc-menu/lib/interface';
import Image from "next/image";

import withTheme from '../../theme/index';
import './ClientLayout.styles.scss';

const { Header, Content, Footer } = Layout;

const MemoizedConnectButton = React.memo(ConnectWallet);

const items: MenuProps['items'] = [
  {
    key: 'home',
    label: (
      <div className="navbar-logo">
        <Image
          src="/clima-logo-ty.png"
          width={500}
          height={500}
          alt="climadao logo icon"
          className="clima-dao-logo"
        />
      </div>
    ),
    className: 'homepage-logo',
  },
  {
    key: 'concept',
    label: 'Concept',
  },
  {
    key: 'register',
    label: 'Register',
  },
  {
    key: 'connect',
    label: (
      <div className="connect-button" onClick={(e) => e.stopPropagation()}>
        <MemoizedConnectButton />
      </div>
    ),
    className: 'homepage-connect-wallet',
  },
];

const pages: { [key in 'home' | 'concept' | 'register']: ComponentType<{}> } = {
  home: dynamic(() => import('../../app/(home)/page'), { ssr: false }),
  concept: dynamic(() => import('../../app/concept/page'), { ssr: false }),
  register: dynamic(() => import('../../app/register/page'), { ssr: false }),
};

type PageKey = keyof typeof pages;

const ClientLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState<PageKey>('home');
  const { address, isConnected } = useAccount();

  const onClick = useCallback((e: MenuInfo) => {
    if (e.key !== 'connect') {
      setCurrentPage(e.key as PageKey);
      router.push(`/${e.key}`);
    }
  }, [router]);

  const RenderedPage = pages[currentPage] || (() => <div>Error: Page not found</div>);

  useEffect(() => {
    if (isConnected && address) {
      const saveUserAddress = async () => {
        try {
          const response = await fetch('/api/mongodb', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ address }),
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          const data = await response.json();
          console.log('User data saved:', data);
        } catch (error) {
          console.error('Failed to save user address:', error);
        }
      };

      saveUserAddress();
    }
  }, [isConnected, address]);

  return withTheme(
    <Layout style={{ height: '100%' }}>
      <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={['home']}
          items={[items[0]]}
          style={{ flex: 1 }}
          onClick={onClick}
          selectedKeys={[currentPage]}
        />
        <Menu
          mode="horizontal"
          items={items.slice(1)}
          style={{ flex: 1, justifyContent: 'flex-end' }}
          onClick={onClick}
          selectedKeys={[currentPage]}
        />
      </Header>
      <Content>
        <RenderedPage />
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        CLIMA DAO {new Date().getFullYear()} Created by 
      </Footer>
    </Layout>
  );
};

export default ClientLayout;
