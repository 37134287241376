"use client";

import React from "react";
import { ConfigProvider } from "antd";

const withTheme = (children: React.ReactNode) => (
  <ConfigProvider
    theme={{
      token: {
        borderRadius: 16,
      },
      components: {
        Menu: {
          horizontalItemSelectedColor: "#253ceb",
          itemHoverColor: '#253ceb',
          activeBarHeight: 0,
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
);

export default withTheme;
